import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CompetitionBlock from "../../components/MainStats/CompetitionBlock"
import LastUpdated from "../../components/LastUpdated/LastUpdated"
import SubPageMenu from '../../components/Utilities/SubPageMenu'
//import CopyBlock from "../../components/Copy/CopyBlock"
// import InfoBar from '../../components/Utilities/InfoBar'

class UEFARankings extends React.Component {

  render() {

    const mdata = this.props.data.allSheetMessiUefaTopXStats.edges
    const rdata = this.props.data.allSheetRonaldoUefaTopXStats.edges

    return (

      <Layout>
        <SEO
          title={"Messi and Ronaldo Stats by UEFA Club Coefficient Ranking - Champions League Goals"}
          description={`How many of Messi and Ronaldo's Champions League goals have come against the teams in the Top 10 of UEFA's Club Coefficient Rankings? Or against the lowest ranked teams?`}
          canonicalPath={`/all-time-stats/uefa-club-rankings/`}
        />

        <h1>
          <span className="sr-only">Messi and Ronaldo</span> Stats by UEFA Club Coefficient Rankings
        </h1>

        <CompetitionBlock competition="vs Top 1" type="all" modalSubTitle="UEFA Champions League Games" subtitle="UEFA Champions League Games" mdata={mdata} rdata={rdata} />

        <CompetitionBlock competition="vs Top 3" type="all" modalSubTitle="UEFA Champions League Games" subtitle="UEFA Champions League Games" mdata={mdata} rdata={rdata} />

        <CompetitionBlock competition="vs Top 5" type="all" modalSubTitle="UEFA Champions League Games" subtitle="UEFA Champions League Games" mdata={mdata} rdata={rdata} />

        <CompetitionBlock competition="vs Top 10" type="all" modalSubTitle="UEFA Champions League Games" subtitle="UEFA Champions League Games" mdata={mdata} rdata={rdata} />

        <CompetitionBlock competition="vs Top 20" type="all" modalSubTitle="UEFA Champions League Games" subtitle="UEFA Champions League Games" mdata={mdata} rdata={rdata} />

        <CompetitionBlock competition="vs Top 30" type="all" modalSubTitle="UEFA Champions League Games" subtitle="UEFA Champions League Games" mdata={mdata} rdata={rdata} />

        <CompetitionBlock competition="vs Top 40" type="all" modalSubTitle="UEFA Champions League Games" subtitle="UEFA Champions League Games" mdata={mdata} rdata={rdata} />

        <CompetitionBlock competition="vs Top 50" type="all" modalSubTitle="UEFA Champions League Games" subtitle="UEFA Champions League Games" mdata={mdata} rdata={rdata} />

        <CompetitionBlock competition="vs 51-75" type="all" modalSubTitle="UEFA Champions League Games" subtitle="UEFA Champions League Games" mdata={mdata} rdata={rdata} />

        <CompetitionBlock competition="vs 76-100" type="all" modalSubTitle="UEFA Champions League Games" subtitle="UEFA Champions League Games" mdata={mdata} rdata={rdata} />

        <CompetitionBlock competition="vs 101-150" type="all" modalSubTitle="UEFA Champions League Games" subtitle="UEFA Champions League Games" mdata={mdata} rdata={rdata} />

        <CompetitionBlock competition="vs 151-200" type="all" modalSubTitle="UEFA Champions League Games" subtitle="UEFA Champions League Games" mdata={mdata} rdata={rdata} />

        <CompetitionBlock competition="vs 201-250" type="all" modalSubTitle="UEFA Champions League Games" subtitle="UEFA Champions League Games" mdata={mdata} rdata={rdata} />

        <LastUpdated type="Stats" />
        
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    allSheetMessiUefaTopXStats(filter: {pgKey: {regex: "/uefa-ranking/"}}) {
      edges {
        node {
          id
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          avgRating
        }
      }
    }
    allSheetRonaldoUefaTopXStats(filter: {pgKey: {regex: "/uefa-ranking/"}}) {
      edges {
        node {
          id
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          avgRating
        }
      }
    }
  }
`

export default UEFARankings
